// @flow
import cx from 'classnames'

import * as React from 'react'

import {HowItWorksCardColor} from './HowItWorksCards'
import {Button} from '../../ui-components'

import * as styles from './HowItWorks.module.css'

import {CREATE_PROTOCOL_DATA} from './how-it-works-data'

export type CreateProtocolProps = {
  active?: boolean,
  onClick?: (event: React.SyntheticMouseEvent<>) => mixed,
}

export default function CreateProtocol(props: CreateProtocolProps) {
  const {active, onClick} = props

  const buttonProps = {
    children: 'upload & run  ›',
    onClick,
    gtmCategory: 'b-hiw',
    gtmAction: 'click',
    gtmLabel: 'button-3',
    className: styles.button,
  }

  return (
    <>
      {active && (
        <div className={styles.container}>
          <div className={styles.get_started_header}>
            <h5 className={styles.header_5}>
              Three free options to create a protocol
            </h5>
            <p className={cx(styles.p, styles.header_p)}>
              Use any (or all!) of these options. Your account manager will help
              you figure out what’s right for you.
            </p>
          </div>
          <div className={styles.color_card_container}>
            <div className={styles.color_card}>
              {CREATE_PROTOCOL_DATA.map(data => {
                return <HowItWorksCardColor key={data.title} {...data} />
              })}
            </div>
            <Button {...buttonProps} />
          </div>
        </div>
      )}
    </>
  )
}
