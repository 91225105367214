// @flow

import * as React from 'react'
import cx from 'classnames'

import {Icon} from '../../../ui-components'

import * as styles from './HowItWorksCards.module.css'

export type HowItWorksCardColorProps = {|
  colorBg: 'black' | 'blue' | 'orange',
  title: string,
  secondTitle: string,
  content: string,
  contentList: Array<{title: string, description: string}>,
  noMargin: boolean,
|}

export default function HowItWorksCardColor(props: HowItWorksCardColorProps) {
  const {colorBg, title, secondTitle, content, contentList, noMargin} = props
  const WHOS_IT_FOR = `Who's it for`
  const ARROWS = 'arrows'
  return (
    <>
      <div
        className={cx(styles.color_card_container, {
          [styles.card_margin]: noMargin,
        })}
      >
        <div
          className={cx(styles.top_half, styles.color_top_half, {
            [styles.black_bg]: colorBg === 'black',
            [styles.blue_bg]: colorBg === 'blue',
            [styles.orange_bg]: colorBg === 'orange',
          })}
        >
          <h6 className={styles.color_title}>{title}</h6>
          <h6 className={styles.color_second_title}>{secondTitle}</h6>
        </div>
        <div className={styles.color_bottom_half}>
          <h6 className={cx(styles.color_card_header, styles.who_it_for)}>
            {WHOS_IT_FOR}
          </h6>
          <h6 className={cx(styles.color_card_header, styles.color_content)}>
            {content}
          </h6>
          <ul>
            {contentList.map((item, i) => {
              return (
                <li className={styles.list} key={i}>
                  <div className={styles.arrow_container}>
                    <Icon
                      className={styles.arrow_img}
                      iconName={ARROWS}
                      noParentDiv={true}
                    />
                    <div>
                      <h6 className={styles.title}>{item.title}</h6>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}
