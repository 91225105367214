// @flow

import * as React from 'react'

import {HowItWorksCardLarge} from './HowItWorksCards'
import {AppLink, Button} from '../../ui-components'

import * as styles from './HowItWorks.module.css'

import type {HowItWorksCardLargeProps} from './HowItWorksCards'

export type ChooseYourToolsProps = {
  active?: boolean,
  onClick?: (event: React.SyntheticMouseEvent<>) => mixed,
}

const LABWARE_URL = 'https://labware.opentrons.com/'

const CHOOSE_YOUR_TOOLS_DATA: HowItWorksCardLargeProps = [
  {
    cardHeader: 'Use standard labware',
    cardSecondHeader: `Common labware is predefined and ready to use`,
    cardContent: (
      <p className={styles.card_content}>
        Find definitions on the{' '}
        {<AppLink url={LABWARE_URL}>Labware Library</AppLink>}
      </p>
    ),
    cardSecondContent:
      'Our growing library of common labware definitions that you can plug right into your protocols.',
  },
  {
    noMargin: true,
    cardHeader: 'Use your custom labware',
    cardSecondHeader: 'Two easy ways to define your custom labware',
    cardDoubleContent: [
      {
        content: 'Create a definition with Labware Creator',
        secondContent: 'Best for simple labware.',
        borderRight: true,
      },
      {
        content: 'Request a custom definition from our team',
        secondContent: 'Best for complex labware.',
        noMargin: true,
      },
    ],
  },
]

export default function ChooseYourTools(props: ChooseYourToolsProps) {
  const {active, onClick} = props

  const buttonProps = {
    children: 'create a protocol ›',
    onClick,
    className: styles.button,
    gtmCategory: 'b-hiw',
    gtmAction: 'click',
    gtmLabel: 'button-2',
  }

  return (
    <>
      {active && (
        <div className={styles.container}>
          <div className={styles.get_started_header}>
            <h5 className={styles.header_5}>Automate your labware</h5>
            <p className={styles.p}>
              Work with your account manager to understand what you need to run
              your first protocol.
            </p>
          </div>
          <div className={styles.choose_your_tool_container}>
            <div className={styles.choose_your_tool}>
              {CHOOSE_YOUR_TOOLS_DATA.map(data => {
                return <HowItWorksCardLarge key={data.cardHeader} {...data} />
              })}
            </div>
            <Button {...buttonProps} />
          </div>
        </div>
      )}
    </>
  )
}
