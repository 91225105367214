// extracted by mini-css-extract-plugin
export var card_container = "HowItWorksCards-module--card_container--8YV2B";
export var card_large_container = "HowItWorksCards-module--card_large_container--OTdqE";
export var color_card_container = "HowItWorksCards-module--color_card_container--Jv7Xu";
export var card_margin = "HowItWorksCards-module--card_margin--rVyMy";
export var card_large_width = "HowItWorksCards-module--card_large_width--j7uh6";
export var card_small_width = "HowItWorksCards-module--card_small_width--eAXQN";
export var card_header = "HowItWorksCards-module--card_header--BGDPj";
export var color_card_header = "HowItWorksCards-module--color_card_header--2L3Kc";
export var card_second_header = "HowItWorksCards-module--card_second_header--mtLr4";
export var card_content = "HowItWorksCards-module--card_content--bJytg";
export var small_card_content = "HowItWorksCards-module--small_card_content--RQxBH";
export var last_card = "HowItWorksCards-module--last_card--mn1Dt";
export var opacity_1 = "HowItWorksCards-module--opacity_1--wohKX";
export var card_box_container = "HowItWorksCards-module--card_box_container--v3gmv";
export var image = "HowItWorksCards-module--image--0fAHt";
export var top_half = "HowItWorksCards-module--top_half--2OaJ3";
export var color_top_half = "HowItWorksCards-module--color_top_half--fe+Qd";
export var color_title = "HowItWorksCards-module--color_title--PXfdt";
export var color_second_title = "HowItWorksCards-module--color_second_title--bfhcQ";
export var who_it_for = "HowItWorksCards-module--who_it_for--OOCyM";
export var color_bottom_half = "HowItWorksCards-module--color_bottom_half--cXq7e";
export var color_content = "HowItWorksCards-module--color_content--d+Jea";
export var arrow_container = "HowItWorksCards-module--arrow_container--cvvR-";
export var title = "HowItWorksCards-module--title--GUpbc";
export var description = "HowItWorksCards-module--description--qe5ZQ";
export var black_bg = "HowItWorksCards-module--black_bg--nvodD";
export var list = "HowItWorksCards-module--list--YTWiI";
export var blue_bg = "HowItWorksCards-module--blue_bg--d6Xnw";
export var orange_bg = "HowItWorksCards-module--orange_bg--uFwiW";
export var bottom_half = "HowItWorksCards-module--bottom_half--LvGFN";
export var large_bottom_half = "HowItWorksCards-module--large_bottom_half--3YAKT";
export var right_border = "HowItWorksCards-module--right_border--ky3Yo";
export var no_margin_left = "HowItWorksCards-module--no_margin_left--WsIVh";
export var arrow_img = "HowItWorksCards-module--arrow_img--6ybSn";