// @flow

import * as React from 'react'
import {AppLink} from '../../ui-components'
import type {
  HowItWorksCardColorProps,
  HowItWorksCardProps,
} from './HowItWorksCards'

export const UPLOAD_RUN_PROTOCOL_DATA: HowItWorksCardProps = [
  {
    imageSrc: require('./assets/number-1.svg').default,
    cardHeader: 'Upload to the app',
    cardContent: 'Upload your protocol file into the Opentrons app.',
  },
  {
    imageSrc: require('./assets/number-2.svg').default,
    cardHeader: 'Calibrate',
    cardContent: 'Calibrate the robot.',
  },
  {
    imageSrc: require('./assets/number-3.svg').default,
    cardHeader: 'Optimize your protocol',
    cardContent: `Opentrons support is there for you along the way.`,
  },
  {
    imageSrc: require('./assets/number-4.svg').default,
    cardHeader: `Press 'run'`,
    cardContent: `You did all the work. Now celebrate.`,
    noMargin: true,
  },
]

export const GET_STARTED_DATA: HowItWorksCardProps = [
  {
    imageSrc: require('./assets/number-1.svg').default,
    cardHeader: 'Demo the OT-2',
    cardContent:
      ' If you do 5 hours of weekly wetlab work, schedule a demo to see if the OT-2 is right for you.',
  },
  {
    imageSrc: require('./assets/number-2.svg').default,
    cardHeader: 'Order a configured OT-2',
    cardContent:
      'Bundle your purchase with pipettes, and pre-validated labware, tips & reagents.',
  },
  {
    imageSrc: require('./assets/number-3.svg').default,
    cardHeader: 'Receive and unbox your OT-2',
    cardContent: `Easy to unbox and set up yourself using our <a href="https://support.opentrons.com/s/ot2-get-started" target="_blank">online guide</a>`,
  },
  {
    imageSrc: require('./assets/number-4.svg').default,
    cardHeader: 'In a hurry or need a custom solution?',
    cardContent: `Contact us for onsite installation, custom protocol development and more!`,
    noMargin: true,
  },
]

const PROTOCOL_LIBRARY = 'https://protocols.opentrons.com/'

export const CREATE_PROTOCOL_DATA: HowItWorksCardColorProps = [
  {
    colorBg: 'blue',
    title: 'code-free interface',
    secondTitle: 'Create your own protocols with Protocol Designer',
    content: 'Those looking for code-free hands-on options for basic protocols',
    contentList: [
      {
        title: 'Simple',
        description: 'Define and then drag & drop any combo of mix, transfer.',
      },
      {
        title: 'Flexible',
        description:
          'Optimize your liquid volumes and labware for an efficient workflow.',
      },
      {
        title: 'Plug-and-play',
        description: 'Select what you want to use and hit "run."',
      },
    ],
  },
  {
    colorBg: 'black',
    title: 'concierge service',
    secondTitle: 'Request a custom protocol from our team',
    content:
      'Those looking to get up and running quickly or make complex protocols ',
    contentList: [
      {
        title: 'Fast',
        description:
          'All communication is done online. No need to wait for an in-person technician visit.',
      },
      {
        title: 'Free',
        description: (
          <p>
            Explore our growing collection of free protocols on the{' '}
            <AppLink url={PROTOCOL_LIBRARY}>Protocol Library</AppLink>
          </p>
        ),
      },
      {
        title: 'Easy',
        description: 'Have a complex workflow? We can handle it. ',
      },
    ],
  },
  {
    colorBg: 'orange',
    title: 'python-based',
    secondTitle: 'Code your own protocols with our Python API',
    content:
      'Python-savvy coders who want to push the boundaries of our open-source ecosystem',
    contentList: [
      {
        title: 'Unlock maximum control',
        description:
          'Use our open-source Python API to fully maximize the potential of the Opentrons system.',
      },
      {
        title: 'Powerful workflows',
        description: 'Never be limited by hardware or software.',
      },
      {
        title: 'Fast',
        description: 'Turn around protocols as quickly as you make them.',
      },
    ],
    noMargin: true,
  },
]

export const STEPS_DATA = [
  {
    name: 'get started',
    menu: 'Started',
    noArrow: false,
    gtm: {action: 'click', category: 'b-hiw', label: 'rail-1'},
  },
  {
    name: 'choose your tools',
    menu: 'Tools',
    noArrow: false,
    gtm: {action: 'click', category: 'b-hiw', label: 'rail-2'},
  },
  {
    name: 'create a protocol',
    menu: 'Protocol',
    noArrow: false,
    gtm: {action: 'click', category: 'b-hiw', label: 'rail-3'},
  },
  {
    name: 'upload, calibrate, optimize, run',
    menu: 'Upload',
    noArrow: false,
    gtm: {action: 'click', category: 'b-hiw', label: 'rail-4'},
  },
  {
    name: 'reproduce, replicate, & share',
    menu: 'Reproduce',
    noArrow: true,
    gtm: {action: 'click', category: 'b-hiw', label: 'rail-5'},
  },
]

export type MenuName = 'Started' | 'Tools' | 'Protocol' | 'Upload' | 'Reproduce'
