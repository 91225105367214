// @flow

import * as React from 'react'
import cx from 'classnames'

import * as styles from './HowItWorksCards.module.css'

export type HowItWorksCardLargeProps = {
  cardHeader: string,
  cardSecondHeader: string,
  cardContent?: string,
  cardSecondContent?: string,
  cardDoubleContent?: Array<{
    content: string,
    secondContent: string,
    borderRight?: boolean,
    noMargin?: boolean,
  }>,
  noMargin: boolean,
  smallWidth?: boolean,
}

export default function HowItWorksCardLarge(props: HowItWorksCardLargeProps) {
  const {
    cardHeader,
    cardContent,
    noMargin,
    cardSecondContent,
    cardSecondHeader,
    cardDoubleContent,
    smallWidth,
  } = props
  return (
    <>
      <div
        className={cx(styles.card_large_container, {
          [styles.card_margin]: noMargin,
          [styles.card_large_width]: cardSecondContent,
          [styles.card_small_width]: smallWidth,
        })}
      >
        <div className={styles.top_half}>
          <h6 className={styles.card_header}>{cardHeader}</h6>
          {cardSecondHeader && (
            <h6 className={cx(styles.card_header, styles.card_second_header)}>
              {cardSecondHeader}
            </h6>
          )}
        </div>
        <div className={styles.card_box_container}>
          {!cardDoubleContent && (
            <div
              className={cx(styles.large_bottom_half, {
                [styles.no_margin_left]: noMargin,
              })}
            >
              <div className={styles.card_content}>{cardContent}</div>
              <div
                className={cx(styles.card_content, styles.last_card, {
                  [styles.opacity_1]: !cardContent,
                })}
              >
                {cardSecondContent}
              </div>
            </div>
          )}
          {cardDoubleContent &&
            cardDoubleContent.map((data, i) => {
              return (
                <div
                  key={i}
                  className={cx(styles.large_bottom_half, {
                    [styles.right_border]: data.borderRight,
                    [styles.no_margin_left]: data.noMargin,
                  })}
                >
                  <div className={styles.card_content}>{data.content}</div>
                  <div className={cx(styles.card_content, styles.last_card)}>
                    {data.secondContent}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}
