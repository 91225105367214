import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import HowItWorks from '../components/HowItWorks'

const HowItWorksPage = () => (
  <Page>
    <SEO title="Opentrons | Open-source Pipetting Robots for Biologists" />
    <HowItWorks />
  </Page>
)

export default HowItWorksPage
