// @flow

import * as React from 'react'
import cx from 'classnames'
import {animateScroll as scroll} from 'react-scroll'
import {Link} from 'gatsby'

import {STEPS_DATA} from './how-it-works-data'

import PATH from './assets/path.svg'

import GetStarted from './GetStarted'
import ChooseYourTools from './ChooseYourTools'
import CreateProtocol from './CreateProtocol'
import UploadRunProtocol from './UploadRunProtocol'
import ReproduceShare from './ReproduceShare'

import {Button} from '../../ui-components'

import * as styles from './HowItWorks.module.css'

import type {MenuName} from './how-it-works-data'

export default function HowItWorks() {
  const STARTED = 'Started'
  const TOOLS = 'Tools'
  const PROTOCOL = 'Protocol'
  const UPLOAD = 'Upload'
  const REPRODUCE = 'Reproduce'

  const [active, setActive] = React.useState(STARTED)

  const toggle = (menu: MenuName) => {
    setActive(menu)
  }

  const toggleButton = (menu: MenuName) => {
    scroll.scrollTo(200)
    setActive(menu)
  }

  const buttonProps = {
    children: 'contact us ›',
    className: styles.reproduce_button,
    to: '/contact',
    orange: true,
    Component: Link,
    gtmCategory: 'b-hiw',
    gtmAction: 'click',
    gtmLabel: 'button-5',
  }

  return (
    <div className={styles.background_color}>
      <div className={styles.container}>
        <div className={styles.how_it_works_header}>
          <h2 className={styles.h2}>How it works</h2>
          <h5 className={styles.h5}>
            Unlock free, powerful, flexible tools—and robust support
          </h5>
        </div>
      </div>
      <div className={styles.step_by_step_bar}>
        {STEPS_DATA.map(data => {
          return (
            <div key={data.name}>
              <span
                className={cx(styles.step_text, {
                  [styles.white_text]: active === data.menu,
                  [styles.visible]: active === data.menu,
                  [styles.step_1]: data.menu === STARTED,
                  [styles.step_2]: data.menu === TOOLS,
                  [styles.step_3]: data.menu === PROTOCOL,
                  [styles.step_4]: data.menu === UPLOAD,
                  [styles.step_5]: data.menu === REPRODUCE,
                  [styles.padding_right_2]: data.noArrow === true,
                })}
                onClick={() => toggle(data.menu)}
                data-gtm-category={data.gtm.category}
                data-gtm-action={data.gtm.action}
                data-gtm-label={data.gtm.label}
              >
                {data.name}
              </span>
              {!data.noArrow && (
                <img
                  src={PATH}
                  className={cx(styles.step_text, styles.path_image)}
                />
              )}
            </div>
          )
        })}
      </div>
      <div className={cx(styles.bg_color, styles.desktop_view)}>
        <GetStarted
          active={active === STARTED}
          onClick={() => toggleButton(TOOLS)}
        />
        <ChooseYourTools
          active={active === TOOLS}
          onClick={() => toggleButton(PROTOCOL)}
        />
        <CreateProtocol
          active={active === PROTOCOL}
          onClick={() => toggleButton(UPLOAD)}
        />
        <UploadRunProtocol
          active={active === UPLOAD}
          onClick={() => toggleButton(REPRODUCE)}
        />
        <ReproduceShare
          active={active === REPRODUCE}
          onClick={() => toggleButton(REPRODUCE)}
        />
      </div>
      <div className={cx(styles.bg_color, styles.mobile_view)}>
        <GetStarted active={true} onClick={() => toggleButton(TOOLS)} />
        <ChooseYourTools active={true} onClick={() => toggleButton(PROTOCOL)} />
        <CreateProtocol active={true} onClick={() => toggleButton(UPLOAD)} />
        <UploadRunProtocol
          active={true}
          onClick={() => toggleButton(REPRODUCE)}
        />
        <ReproduceShare active={true} onClick={() => toggleButton(REPRODUCE)} />
        <div className={styles.container}>
          <p className={styles.mobile_p}>Still have questions?</p>
          <Button {...buttonProps} />
        </div>
      </div>
    </div>
  )
}
