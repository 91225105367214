// @flow

import * as React from 'react'

import {Link} from 'gatsby'

import {Button} from '../../ui-components'

import {HowItWorksCardLarge} from './HowItWorksCards'

import * as styles from './HowItWorks.module.css'

export type ReproduceShareProps = {
  active: boolean,
}

const CHOOSE_YOUR_TOOLS_DATA = [
  {
    cardHeader: 'Replicate',
    cardSecondHeader: 'Easily redo your exact protocol',
    cardSecondContent:
      'Replicate or adjust your optimized protocol with minimal setup time.',
    smallWidth: true,
  },
  {
    noMargin: true,
    cardHeader: 'Share your work!',
    cardSecondHeader:
      'With open-source we can empower scientists and move discovery forward',
    cardDoubleContent: [
      {
        content: 'With your labmates',
        secondContent:
          'Share your protocol with labmates or other labs using the OT-2.',
        borderRight: true,
      },
      {
        content: 'With the science community',
        secondContent:
          'Protocols shared with and verified by Opentrons will be added to the open-source Protocol Library.',
        borderRight: true,
        noMargin: true,
      },
      {
        content: 'In publications',
        secondContent:
          'Include your protocol in your publication to encourage reproducibility.',
        noMargin: true,
      },
    ],
  },
]

export default function ReproduceShare(props: ReproduceShareProps) {
  const {active} = props

  const buttonProps = {
    children: 'contact us ›',
    className: styles.button,
    to: '/contact',
    orange: true,
    Component: Link,
    gtmCategory: 'b-hiw',
    gtmAction: 'click',
    gtmLabel: 'button-5',
  }

  const CONTENT = `Automation changes the way you make discoveries in your lab and across the globe.`

  return (
    <>
      {active && (
        <div className={styles.container}>
          <div className={styles.get_started_header}>
            <h5 className={styles.header_5}>
              Reproduce, replicate &amp; share
            </h5>
            <p className={styles.p}>{CONTENT}</p>
          </div>
          <div className={styles.get_started_container}>
            <div className={styles.color_card}>
              {CHOOSE_YOUR_TOOLS_DATA.map((data, i) => {
                return <HowItWorksCardLarge key={i} {...data} />
              })}
            </div>
            <Button {...buttonProps} />
          </div>
        </div>
      )}
    </>
  )
}
