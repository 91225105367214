// @flow

import * as React from 'react'
import cx from 'classnames'

import * as styles from './HowItWorksCards.module.css'

export type HowItWorksCardProps = {
  imageSrc: string,
  cardHeader: string,
  cardContent: string,
  noMargin?: boolean,
}

export default function HowItWorksCard(props: HowItWorksCardProps) {
  const {imageSrc, cardHeader, cardContent, noMargin} = props
  return (
    <>
      <div
        className={cx(styles.card_container, {
          [styles.card_margin]: noMargin,
        })}
      >
        <div className={styles.top_half}>
          <img className={styles.image} src={imageSrc} alt="" />
          <h6 className={styles.card_header}>{cardHeader}</h6>
        </div>
        <div className={styles.bottom_half}>
          <p
            className={styles.small_card_content}
            dangerouslySetInnerHTML={{__html: cardContent}}
          />
        </div>
      </div>
    </>
  )
}
