// extracted by mini-css-extract-plugin
export var container = "HowItWorks-module--container--xsTUd";
export var background_color = "HowItWorks-module--background_color--yWBx2";
export var h2 = "HowItWorks-module--h2--4iRpB";
export var header_5 = "HowItWorks-module--header_5--G3bF+";
export var h5 = "HowItWorks-module--h5--G7XFM";
export var p = "HowItWorks-module--p--sQ+8U";
export var how_it_works_header = "HowItWorks-module--how_it_works_header--OkRLk";
export var step_by_step_bar = "HowItWorks-module--step_by_step_bar--4O0p0";
export var card_content = "HowItWorks-module--card_content--z1gSP";
export var step_text = "HowItWorks-module--step_text--i3jZr";
export var padding_right_2 = "HowItWorks-module--padding_right_2--Q4-3m";
export var step_1 = "HowItWorks-module--step_1--T4bC7";
export var step_2 = "HowItWorks-module--step_2--5Sd5s";
export var step_3 = "HowItWorks-module--step_3--myxsL";
export var step_4 = "HowItWorks-module--step_4--94dpQ";
export var step_5 = "HowItWorks-module--step_5--cta+8";
export var visible = "HowItWorks-module--visible--shkLz";
export var white_text = "HowItWorks-module--white_text--PG85z";
export var get_started_header = "HowItWorks-module--get_started_header--MX076";
export var header_p = "HowItWorks-module--header_p--w48ra";
export var card_container = "HowItWorks-module--card_container--mNcfx";
export var desktop_view = "HowItWorks-module--desktop_view--HyhK4";
export var mobile_view = "HowItWorks-module--mobile_view--GIZu9";
export var button = "HowItWorks-module--button--MyC8b";
export var mobile_p = "HowItWorks-module--mobile_p--mNFh6";
export var reproduce_button = "HowItWorks-module--reproduce_button--jQI2p";
export var choose_your_tool = "HowItWorks-module--choose_your_tool--9dOjY";
export var choose_your_tool_container = "HowItWorks-module--choose_your_tool_container--iArql";
export var get_started_container = "HowItWorks-module--get_started_container--L9Jdh";
export var bg_color = "HowItWorks-module--bg_color--k51sr";
export var path_image = "HowItWorks-module--path_image--4QrjO";
export var color_card = "HowItWorks-module--color_card--ctWqV";
export var color_card_container = "HowItWorks-module--color_card_container--J1MMJ";