// @flow

import * as React from 'react'

import {Button} from '../../ui-components'

import {HowItWorksCard} from './HowItWorksCards'

import {GET_STARTED_DATA} from './how-it-works-data'

import * as styles from './HowItWorks.module.css'

export type GetStartedProps = {
  active?: boolean,
  onClick?: (event: React.SyntheticMouseEvent<>) => mixed,
}

export default function GetStarted(props: GetStartedProps) {
  const {active, onClick} = props

  const buttonProps = {
    children: 'choose your tools ›',
    className: styles.button,
    gtmCategory: 'b-hiw',
    gtmAction: 'click',
    gtmLabel: 'button-1',
    onClick,
  }

  const CONTENT = `Everything you need to automate your lab, including help at every step`

  return (
    <>
      {active && (
        <div className={styles.container}>
          <div className={styles.get_started_header}>
            <h5 className={styles.header_5}>Get started</h5>
            <p className={styles.p}>{CONTENT}</p>
          </div>
          <div className={styles.get_started_container}>
            <div className={styles.card_container}>
              {GET_STARTED_DATA.map((data, i) => {
                return <HowItWorksCard key={i} {...data} />
              })}
            </div>
            <Button {...buttonProps} />
          </div>
        </div>
      )}
    </>
  )
}
