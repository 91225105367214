// @flow

import * as React from 'react'

import {Button} from '../../ui-components'

import {HowItWorksCard} from './HowItWorksCards'

import {UPLOAD_RUN_PROTOCOL_DATA} from './how-it-works-data'

import * as styles from './HowItWorks.module.css'

export type UploadRunProtocolProps = {
  active?: boolean,
  onClick?: (event: React.SyntheticMouseEvent<>) => mixed,
}

export default function UploadRunProtocol(props: UploadRunProtocolProps) {
  const {active, onClick} = props

  const buttonProps = {
    children: 'reproduce & share  ›',
    className: styles.button,
    gtmCategory: 'b-hiw',
    gtmAction: 'click',
    gtmLabel: 'button-4',
    onClick,
  }

  return (
    <>
      {active && (
        <div className={styles.container}>
          <div className={styles.get_started_header}>
            <h5 className={styles.header_5}>
              Upload, run, and replicate your protocols
            </h5>
            <p className={styles.p}>
              Free standard support for the life of your OT-2 has you covered.
            </p>
          </div>
          <div className={styles.get_started_container}>
            <div className={styles.card_container}>
              {UPLOAD_RUN_PROTOCOL_DATA.map((data, i) => {
                return <HowItWorksCard key={i} {...data} />
              })}
            </div>
            <Button {...buttonProps} />
          </div>
        </div>
      )}
    </>
  )
}
